<template>
  <v-container>
    <v-card v-if="survey">
      <v-card-title class="bg-primary text-uppercase">{{ survey.title }}</v-card-title>
      <v-divider></v-divider>
      <v-stepper alt-labels flat :value="parseInt(survey.currentPageNo) + 1">
        <v-stepper-header>
          <v-stepper-step
            v-for="page in survey.visiblePages"
            :key="page.name"
            :step="parseInt(page.num)"
            :complete="survey.currentPageNo >= page.num"
          >
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-divider></v-divider>
      <v-card-text>
        <Survey :survey="survey"></Survey>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn :disabled="survey.isFirstPage" color="primary" @click="goToPreviousPage">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn :disabled="survey.isLastPage" color="primary" @click="goToNextPage">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="!preview" :disabled="!isLastPage" color="primary" @click="finish">{{ $t("dialog.finish") }}</v-btn>
        <v-btn v-if="preview" color="primary" :to="{ name: 'Surveys' }">{{ $t("dialog.exit") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-skeleton-loader v-else type="article"></v-skeleton-loader>
  </v-container>
</template>

<script>
import surveyMixin from "./mixins/survey";
import gql from "graphql-tag";
import { Survey } from "survey-vue-ui";

export default {
  name: "SurveyPreview",
  mixins: [surveyMixin],
  components: { Survey },
  props: {
    id: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      surveyData: {},
      survey: null,
    };
  },
  apollo: {
    surveyData: {
      query: gql`
        query Survey($id: ID!) {
          survey(id: $id) {
            id
            data
          }
        }
      `,
      variables() {
        return { id: this.id };
      },
      update(data) {
        this.survey = this.buildSurvey(data.survey);
        return data.survey;
      },
    },
  },
};
</script>
